<template>
  <div class="xinsheng-warp distribution">
    <el-form class="form-block">
      <el-form-item label-width="170px">
        <template #label>
          客户分配规则设置
          <el-tooltip
            class="item"
            effect="dark"
            content="客户自动进入回流池小时数设置"
            placement="top-start"
          >
            <span class="question">?</span>
          </el-tooltip>
          ：
        </template>
        <el-row class="row-block">
          <el-col>
            <el-radio-group v-model="backFlowStatus">
              <el-radio :label="0">不启用</el-radio>
              <el-radio :label="1">启用</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row
          class="row-block"
          v-for="(customer,index) in customers"
          :key="index"
        >
          <el-col
            :span="12"
            v-for="(cus, ind) in customer"
            :key="ind"
            class="col-block"
          >
            <div class="label">{{cus.title}}</div>
            <el-input
              class="col-input"
              v-model="cus.value"
              type="number"
              size="small"
            ></el-input>
            <div class="label">小时不跟进，流入回流池</div>
            <div class="label" style="margin-left: 10px">
              <el-checkbox v-model="cus.channel" label="渠道" size="default"></el-checkbox>
              <el-checkbox v-model="cus.interior" label="内部" size="default"></el-checkbox>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <div class="line"></div>
<!--      <el-form-item label-width="170px">-->
<!--        <template #label>-->
<!--          分配数量规则-->
<!--          <el-tooltip-->
<!--            class="item"-->
<!--            effect="dark"-->
<!--            content="公司每日新资源分配数量"-->
<!--            placement="top-start"-->
<!--          >-->
<!--            <span class="question">?</span>-->
<!--          </el-tooltip>-->
<!--          ：-->
<!--        </template>-->
<!--        <el-row class="row-block">-->
<!--          <el-col>-->
<!--            <el-radio-group v-model="distributionStatus">-->
<!--              <el-radio :label="0">不启用</el-radio>-->
<!--              <el-radio :label="1">启用</el-radio>-->
<!--            </el-radio-group>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row class="row-block">-->
<!--          <el-col-->
<!--            :span="24"-->
<!--            class="col-block"-->
<!--          >-->
<!--            <div class="label">XX网信息咨询有限公司：分配</div>-->
<!--            <el-input-->
<!--              v-model="distributionNum"-->
<!--              class="col-input"-->
<!--              type="number"-->
<!--              size="small"-->
<!--            ></el-input>-->
<!--            <div class="label">条数据，已分配 0 条数据</div>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-form-item>-->
      <el-form-item label-width="170px">
        <template #label>
          新资源重新分配
          <el-tooltip
            class="item"
            effect="dark"
            content="新资源不跟进自动分配他人"
            placement="top-start"
          >
            <span class="question">?</span>
          </el-tooltip>
          ：
        </template>
        <el-row class="row-block">
          <el-col>
            <el-radio-group v-model="newCustomerStatus">
              <el-radio :label="0">不启用</el-radio>
              <el-radio :label="1">启用</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row class="row-block">
          <el-col
            :span="24"
            class="col-block"
          >
            <div class="label">新资源：</div>
            <el-input
              class="col-input"
              type="number"
              size="small"
              v-model="newCustomerMinute"
            ></el-input>
            <div class="label">分钟不跟进，自动分配他人</div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label-width="170px">
        <el-button
          type="primary"
          size="small"
          @click="handleSubmit"
        >保存</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import {
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
  ElRadio,
  ElRadioGroup,
  ElRow,
  ElCol,
  ElTooltip,
  ElMessage,
  ElCheckbox,
} from "element-plus";
import { onMounted, reactive, toRefs } from "vue";
import { getRules, putRules } from "@/api/method/system";

export default {
  name: "Distribution",
  components: {
    [ElForm.name]: ElForm,
    [ElFormItem.name]: ElFormItem,
    [ElInput.name]: ElInput,
    [ElRadioGroup.name]: ElRadioGroup,
    [ElRadio.name]: ElRadio,
    [ElButton.name]: ElButton,
    [ElTooltip.name]: ElTooltip,
    [ElRow.name]: ElRow,
    [ElCol.name]: ElCol,
    [ElCheckbox.name]: ElCheckbox,
  },
  setup() {
    const customers = reactive([
      [
        {
          title: "重要客户：",
          value: null,
          channel: false,
          interior: false,
        },
        {
          title: "2星客户：",
          value: null,
          channel: false,
          interior: false,
        },
      ],
      [
        {
          title: "0星客户：",
          value: null,
          channel: false,
          interior: false,
        },
        {
          title: "3星客户：",
          value: null,
          channel: false,
          interior: false,
        },
      ],
      [
        {
          title: "1星客户：",
          value: null,
          channel: false,
          interior: false,
        },
        {
          title: "4星客户：",
          value: null,
          channel: false,
          interior: false,
        },
      ],
      [
          {
            title: "5星客户：" ,
            value: null,
            channel: false,
            interior: false
          },
          {
            title: "在途客户：" ,
            value: null,
            channel: false,
            interior: false
          }
        ],
      [
          {
        title: "-1星客户：" ,
        value: null,
        channel: false,
        interior: false
        },
          {
            title: "4星+客户：" ,
            value: null,
            channel: false,
            interior: false
          },
        ],
    ]);

    const rulesDetail = async () => {
      const res = await getRules(1);
      const extras = JSON.parse(res.data.extra);
      const extraMap = {};

      for (let i = 0; i < extras.length; i++) {
        let item = extras[i];
        extraMap[item.starNum] = item;
      }

      customers[0][0].value = res.data.lockStatusNum;
      customers[0][0].channel = extraMap["-1"].channel;
      customers[0][0].interior = extraMap["-1"].interior;

      customers[0][1].value = res.data.twoStarNum;
      customers[0][1].channel =  extraMap["2"].channel;
      customers[0][1].interior = extraMap["2"].interior;

      customers[1][0].value = res.data.zeroStarNum;
      customers[1][0].channel =  extraMap["0"].channel;
      customers[1][0].interior =  extraMap["0"].interior;

      customers[1][1].value = res.data.threeStarNum;
      customers[1][1].channel =  extraMap["3"].channel;
      customers[1][1].interior =  extraMap["3"].interior;

      customers[2][0].value = res.data.oneStarNum;
      customers[2][0].channel = extraMap["1"].channel;
      customers[2][0].interior =  extraMap["1"].interior;

      customers[2][1].value = res.data.fourStarNum;
      customers[2][1].channel =  extraMap["4"].channel;
      customers[2][1].interior =  extraMap["4"].interior;

      customers[3][0].value = res.data.fiveStarNum;
      customers[3][0].channel =  extraMap["5"].channel;
      customers[3][0].interior =  extraMap["5"].interior;

      customers[3][1].value = res.data.onRodeNum;
      customers[3][1].channel =  extraMap["-2"].channel;
      customers[3][1].interior =  extraMap["-2"].interior;

      customers[4][0].value = res.data.minusStarNum;
      customers[4][0].channel =  extraMap["-1.1"].channel;
      customers[4][0].interior =  extraMap["-1.1"].interior;

      customers[4][1].value = res.data.fourPlusNum;
      customers[4][1].channel =  extraMap["4.5"].channel;
      customers[4][1].interior =  extraMap["4.5"].interior;

      formData.backFlowStatus = res.data.backFlowStatus;
      formData.distributionStatus = res.data.distributionStatus;
      formData.distributionNum = res.data.distributionNum;
      formData.newCustomerStatus = res.data.newCustomerStatus;
      formData.newCustomerMinute = res.data.newCustomerMinute;
    };

    const formData = reactive({
      id: 1,
      backFlowStatus: null,
      distributionStatus: null,
      distributionNum: null,
      newCustomerStatus: null,
      newCustomerMinute: null,
    });

    const handleSubmit = async () => {
      const extra = [];
      extra.push({starNum: -1, channel: customers[0][0].channel, interior:customers[0][0].interior })
      extra.push({starNum: 2, channel: customers[0][1].channel, interior:customers[0][1].interior })
      extra.push({starNum: 0, channel: customers[1][0].channel, interior:customers[1][0].interior })
      extra.push({starNum: 3, channel: customers[1][1].channel, interior:customers[1][1].interior })
      extra.push({starNum: 1, channel: customers[2][0].channel, interior:customers[2][0].interior })
      extra.push({starNum: 4, channel: customers[2][1].channel, interior:customers[2][1].interior })
      extra.push({starNum: 5, channel: customers[3][0].channel, interior:customers[3][0].interior })
      extra.push({starNum: -2, channel: customers[3][1].channel, interior:customers[3][1].interior })
      extra.push({starNum: -1.1, channel: customers[4][0].channel, interior:customers[4][0].interior })
      extra.push({starNum: 4.5, channel: customers[4][1].channel, interior:customers[4][1].interior })

      const starNum = {
        lockStatusNum: customers[0][0].value,
        twoStarNum: customers[0][1].value,
        zeroStarNum: customers[1][0].value,
        threeStarNum: customers[1][1].value,
        oneStarNum: customers[2][0].value,
        fourStarNum: customers[2][1].value,
        fiveStarNum: customers[3][0].value,
        onRodeNum: customers[3][1].value,
        minusStarNum: customers[4][0].value,
        fourPlusNum: customers[4][1].value,

        extra: JSON.stringify(extra),
      };
      const res = await putRules({ ...formData, ...starNum });
      if (res.code == 200) {
        ElMessage.success("保存成功");
      } else {
        ElMessage.error(res.msg);
      }
    };

    onMounted(() => {
      rulesDetail();
    });

    return {
      customers,
      ...toRefs(formData),
      handleSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/css/form";
.distribution {
  .row-block {
    margin-bottom: 16px;
  }
  .col-block {
    display: flex;
    .label {
      min-width: 70px;
    }
  }
  .form-block {
    .col-input {
      width: 130px;
      margin: 0 4px;
    }
  }

  .line {
    height: 1px;
    background: rgb(237, 237, 240);
    margin: 12px 0px;
  }
}
</style>